import React from "react";
import { Seo } from "../components/Helpers/Seo";
import { Listings } from "../components/Listings/Listings";
import * as s from "../styles/pages/PostTypeIndex.module.scss";
import Layout from "../components/Layout/Layout";
import { Container } from "react-bootstrap";
import Header from "../components/Header/Header";
import { graphql } from "gatsby";

// call out seo properties here
export const Head = ({ data }) => <Seo
    title={data.wpPage.seo.title}
    description={data.wpPage.seo.metaDesc}
    keywords={data.wpPage.seo.metaKeywords}
/>;


const NewsMedia = ({ data }) => {
    const allNewsMedia = data.allWpPost.nodes;
    const pageInfo = data.wpPage;
    return (
        <Layout footerBackgroundClass="bg-white">
            <Container fluid>
                <Header />

                <section className={s.section}>
                    <Container>
                        <h1 className={s.section__title}>{pageInfo.title}</h1>

                        {/* news & media listing */}
                        <div className="listing__wrapper">
                            {allNewsMedia.map((news) => {
                                const {
                                    id,
                                    title,
                                    acfNewsMedia: { authors, date, link, source },
                                } = news;
                                return (
                                    <Listings
                                        id={id}
                                        key={id}
                                        date={date}
                                        source={source}
                                        title={title}
                                        link={link}
                                        authors={authors}
                                    />
                                );
                            })}
                        </div>
                    </Container>
                </section>
            </Container>
        </Layout>
    );
};

export default NewsMedia;

export const pageQuery = graphql`
    query GetAllNewsMedia {
        allWpPost(filter: { categories: { nodes: { elemMatch: { databaseId: { ne: 163 } } } } }, sort:{fields: date, order:DESC}) {
            nodes {
                id
                title
                date
                acfNewsMedia {
                    authors
                    source
                    date
                    link {
                        target
                        url
                    }
                }
            }
            
        }
        wpPage(databaseId: { eq: 21 }){
            seo{
                metaDesc
                title
                metaKeywords
            }
            title
        }
    }
`;
